import styled from 'styled-components';

const DashboardWrapper = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 90dvh;
  background-color: #f5f5f5;
  padding: 20px;
`;

const DashboardCard = styled.div`
  max-width: 600px;
  width: 100%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  overflow: hidden;
  background-color: white;
`;

const DashboardHeader = styled.div`
  background-color: #37474f;
  color: white;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DashboardLogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #e0e0e0;

  svg {
    width: 200px;
    height: auto;
  }
`;

const DashboardContent = styled.div`
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: #37474f;
  padding: 20px;
`;

const DashboardFooter = styled.div`
  background-color: #fafafa;
  padding: 16px;
  text-align: center;
  font-size: 14px;
  color: #757575;
  border-top: 1px solid #e0e0e0;
`;

export {
  DashboardWrapper as Wrapper,
  DashboardCard as Card,
  DashboardHeader as Header,
  DashboardLogoContainer as LogoContainer,
  DashboardContent as Content,
  DashboardFooter as Footer,
};
