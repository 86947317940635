import React, { useEffect } from 'react';
import { Route } from 'react-router';
import {
  Admin as RAAdmin,
  AppBar as RAAppBar,
  CustomRoutes,
  Layout as RALayout,
  Resource,
  UserMenu as RAUserMenu,
} from 'react-admin';
import simpleRestProvider from 'ra-data-simple-rest';
import ListAltRoundedIcon from '@mui/icons-material/ListAltRounded';
import LaunchIcon from '@mui/icons-material/Launch';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import RecentActorsRoundedIcon from '@mui/icons-material/RecentActorsRounded';
import HandshakeIcon from '@mui/icons-material/Handshake';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';

import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { QuestCreate, QuestList, QuestEdit, QuestShow } from '../Quest';
import { LogoutButton } from '../Shared/LogoutButton';
import { LoginPage } from '../LoginPage';
import { ADMIN_PANEL_SERVER_URL } from '../App.config';
import { Dashboard } from '../Dashboard';
import { ForbiddenPage } from '../ForbiddenPage';
import { myTheme } from './theme';
import useAuthProvider from './useAuthProvider';
import { AdminCreate, AdminList, AdminEdit } from '../AdminPage';
import { checkPermission, httpClient } from './Admin.utilities';
import { AdminAccessRightListEnum } from '../Shared/Admin.types';
import { AdminLogList, AdminLogShow } from '../AdminLog';
import { GameList } from '../Games/GameList';
import { InternalSupportPage } from '../InternalSupport';
import {
  PartnerList,
  PartnerShow,
  PartnerCreate,
  PartnerEdit,
} from '../Partner';
import {
  AirdropQuestList,
  AirdropQuestCreate,
  AirdropQuestEdit,
} from '../AirdropQuest';
import {
  InterSeasonList,
  InterSeasonCreate,
  InterSeasonEdit,
  InterSeasonShow,
} from '../Story/InterSeason';
import {
  FollowUpCreate,
  FollowUpEdit,
  FollowUpShow,
  FollowUpList,
} from '../Story/FollowUp';
import { useNavigate } from 'react-router-dom';

const dataProvider = simpleRestProvider(ADMIN_PANEL_SERVER_URL, httpClient);

const UserMenu: React.FC = () => (
  <RAUserMenu>
    <LogoutButton />
  </RAUserMenu>
);

const AppBar: React.FC = () => <RAAppBar userMenu={<UserMenu />} />;

const Layout: React.FC = (props) => <RALayout {...props} appBar={AppBar} />;

const resources = [
  {
    name: 'quests',
    permissions: [
      AdminAccessRightListEnum.AllPermissions,
      AdminAccessRightListEnum.ManageQuests,
      AdminAccessRightListEnum.ReadQuests,
      AdminAccessRightListEnum.CreateQuests,
      AdminAccessRightListEnum.UpdateQuests,
    ],
    components: {
      list: QuestList,
      create: QuestCreate,
      edit: QuestEdit,
      show: QuestShow,
    },
    icon: ListAltRoundedIcon,
  },
  {
    name: 'adquests',
    permissions: [
      AdminAccessRightListEnum.AllPermissions,
      AdminAccessRightListEnum.ManageAirDropQuests,
      AdminAccessRightListEnum.ReadAirDropQuests,
      AdminAccessRightListEnum.CreateAirDropQuests,
      AdminAccessRightListEnum.UpdateAirDropQuests,
    ],
    components: {
      list: AirdropQuestList,
      create: AirdropQuestCreate,
      edit: AirdropQuestEdit,
    },
    icon: LaunchIcon,
    options: { label: 'Airdrop Quests' },
  },
  {
    name: 'seasonstory',
    permissions: [
      AdminAccessRightListEnum.AllPermissions,
      AdminAccessRightListEnum.ManageStorySeason,
      AdminAccessRightListEnum.ReadStorySeason,
      AdminAccessRightListEnum.CreateStorySeason,
      AdminAccessRightListEnum.UpdateStorySeason,
    ],
    components: {
      list: InterSeasonList,
      create: InterSeasonCreate,
      edit: InterSeasonEdit,
      show: InterSeasonShow,
    },
    icon: AutoStoriesIcon,
    options: { label: 'Season Story' },
  },
  {
    name: 'storymessages',
    permissions: [
      AdminAccessRightListEnum.AllPermissions,
      AdminAccessRightListEnum.ManageStoryFollowUp,
      AdminAccessRightListEnum.ReadStoryFollowUp,
      AdminAccessRightListEnum.CreateStoryFollowUp,
      AdminAccessRightListEnum.UpdateStoryFollowUp,
    ],
    components: {
      list: FollowUpList,
      create: FollowUpCreate,
      edit: FollowUpEdit,
      show: FollowUpShow,
    },
    icon: AutoStoriesIcon,
    options: { label: 'Story FollowUp' },
  },

  {
    name: 'admins',
    permissions: [
      AdminAccessRightListEnum.AllPermissions,
      AdminAccessRightListEnum.ManageAdmins,
      AdminAccessRightListEnum.ReadAdmins,
      AdminAccessRightListEnum.CreateAdmins,
      AdminAccessRightListEnum.UpdateAdmins,
    ],
    components: { list: AdminList, create: AdminCreate, edit: AdminEdit },
    icon: PeopleAltRoundedIcon,
  },
  {
    name: 'adminlogs',
    permissions: [
      AdminAccessRightListEnum.AllPermissions,
      AdminAccessRightListEnum.ManageAdminlogs,
      AdminAccessRightListEnum.ReadAdminlogs,
    ],
    components: { list: AdminLogList, show: AdminLogShow },
    icon: RecentActorsRoundedIcon,
    options: { label: 'Admin Logs' },
  },
  {
    name: 'games',
    permissions: [
      AdminAccessRightListEnum.AllPermissions,
      AdminAccessRightListEnum.ManageGames,
      AdminAccessRightListEnum.ReadGames,
    ],
    components: { list: GameList },
    icon: SportsEsportsIcon,
    options: { label: 'Games' },
  },
  {
    name: 'partners',
    permissions: [
      AdminAccessRightListEnum.AllPermissions,
      AdminAccessRightListEnum.ManagePartners,
      AdminAccessRightListEnum.ReadPartners,
      AdminAccessRightListEnum.CreatePartners,
      AdminAccessRightListEnum.UpdatePartners,
    ],
    components: {
      list: PartnerList,
      create: PartnerCreate,
      edit: PartnerEdit,
      show: PartnerShow,
    },
    icon: HandshakeIcon,
    options: { label: 'Partners' },
  },
  {
    name: 'internalsupport',
    permissions: [AdminAccessRightListEnum.ReadInternalsupport],
    components: { list: InternalSupportPage },
    icon: SupportAgentIcon,
    options: { label: 'Internal Support' },
  },
];

const renderResources = (permissions: AdminAccessRightListEnum[]): any => {
  return resources.map(
    ({ name, permissions: requiredPermissions, components, icon, options }) => {
      if (!checkPermission(permissions, requiredPermissions, true)) return null;

      return (
        <Resource
          key={name}
          name={name}
          list={components.list}
          create={components.create}
          edit={components.edit}
          show={components.show}
          icon={icon}
          options={options}
        />
      );
    }
  );
};

const Admin: React.FC = () => {
  const authProvider = useAuthProvider();
  const navigate = useNavigate();

  useEffect(() => {
    if (!authProvider?.socket?.id) {
      navigate('/login');
    }
  }, []);

  return (
    <RAAdmin
      theme={myTheme}
      authProvider={authProvider}
      dashboard={Dashboard}
      dataProvider={dataProvider}
      disableTelemetry
      layout={Layout}
      loginPage={LoginPage}
      requireAuth
    >
      {(permissions) => (
        <>
          <CustomRoutes>
            <Route path="/forbidden" element={<ForbiddenPage />} />
          </CustomRoutes>
          {renderResources(permissions)}
        </>
      )}
    </RAAdmin>
  );
};

export default Admin;
