import React from 'react';
import { HeaderTitle } from '../Shared/HeaderTitle';
import { ReactComponent as Logo } from './logo.svg';
import * as S from './Dashboard.styled';

const Dashboard: React.FC = () => {
  return (
    <S.Wrapper>
      <S.Card>
        <S.Header>
          <HeaderTitle />
        </S.Header>

        <S.LogoContainer>
          <Logo />
        </S.LogoContainer>

        <S.Content>Welcome to the Dashboard</S.Content>

        <S.Footer>
          For internal use only. If you face any issues, please contact
          Mete/Josue.
        </S.Footer>
      </S.Card>
    </S.Wrapper>
  );
};

export default Dashboard;
