import React, { FC } from 'react';
import {
  ChipField,
  CreateButton,
  Datagrid,
  EditButton,
  ExportButton,
  FunctionField,
  ListBase,
  ShowButton,
  SimpleListConfigurable,
  TextField,
  TextInput,
  Title,
  TopToolbar,
  usePermissions,
} from 'react-admin';
import { EmptyFieldWithRecord } from '../../../Shared/EmptyFieldWithRecord';
import { WithLoading } from '../../../Shared/Loading';
import { Box, Stack, Tooltip, useMediaQuery } from '@mui/material';
import { FilterForm } from '../../../Shared/FilterForm';
import styled from 'styled-components';
import { checkPermission } from '../../../Admin/Admin.utilities';
import { AdminAccessRightListEnum } from '../../../Shared/Admin.types';

const truncate = (text: string, maxLength: number): string => {
  if (text.length <= maxLength) return text;
  return `${text.substring(0, maxLength)}...`;
};

const StyledTextInput = styled(TextInput)`
  .MuiInputBase-input:focus ~ div .MuiButtonBase-root {
    color: #ff5722;
  }
`;

const filters = (fullWidth: boolean): React.ReactNode[] => {
  return [
    <StyledTextInput
      key="filterSearchInput"
      label="Search"
      source="q"
      alwaysOn
      fullWidth={fullWidth}
      resettable
    />,
  ];
};

const ListActions: React.FC = () => {
  const isSmallDevice = useMediaQuery((theme: any) =>
    theme.breakpoints.down('sm')
  );
  const { permissions } = usePermissions();

  const hasCreateButton = checkPermission(permissions, [
    AdminAccessRightListEnum.CreateStorySeason,
  ]);

  return (
    <Stack
      direction={isSmallDevice ? 'column' : 'row'}
      marginTop={2}
      marginBottom={2}
    >
      <FilterForm filters={filters(isSmallDevice)} fullWidth={isSmallDevice} />
      <TopToolbar
        sx={{
          backgroundColor: 'transparent!important',
          width: '100%',
        }}
      >
        {hasCreateButton && <CreateButton />}
        <ExportButton />
      </TopToolbar>
    </Stack>
  );
};

const InterSeasonList: FC = () => {
  const isSmallDevice = useMediaQuery((theme: any) =>
    theme.breakpoints.down('sm')
  );
  const { permissions } = usePermissions();

  const hasUpdateButtons = checkPermission(permissions, [
    AdminAccessRightListEnum.UpdateStorySeason,
  ]);

  return (
    <ListBase perPage={10} sort={{ field: 'id', order: 'DESC' }}>
      <Title title="Galactic Entertainment" />
      <ListActions />
      <WithLoading>
        {isSmallDevice ? (
          <SimpleListConfigurable
            primaryText={(record) => record.titleStory}
            secondaryText={(record) => record.titleConclusion}
            tertiaryText={(record) => record.status}
            linkType="show"
          />
        ) : (
          <Datagrid
            bulkActionButtons={hasUpdateButtons as any}
            rowClick="show"
            sx={{
              '& .column-actions': {
                textAlign: 'right',
                '& .MuiStack-root': {
                  justifyContent: 'flex-end',
                },
                '& .MuiButtonBase-root': {
                  minWidth: 'max-content',
                },
                '& .MuiButton-startIcon': {
                  marginRight: '0',
                },
              },
            }}
          >
            <EmptyFieldWithRecord source="id" label="ID">
              <TextField source="id" label="ID" />
            </EmptyFieldWithRecord>

            <EmptyFieldWithRecord source="titleStory" label="Title Story">
              <TextField source="titleStory" />
            </EmptyFieldWithRecord>
            <EmptyFieldWithRecord source="messageStory" label="Message Story">
              <FunctionField
                source="messageStory"
                label="Message Story"
                render={(record: any) => {
                  return (
                    <Tooltip
                      title={
                        <Box
                          sx={{
                            maxHeight: '150px',
                            overflowY: 'auto',
                          }}
                        >
                          {record.messageStory}
                        </Box>
                      }
                      arrow
                    >
                      <span>{truncate(record.messageStory, 30)}</span>
                    </Tooltip>
                  );
                }}
              />
            </EmptyFieldWithRecord>
            <EmptyFieldWithRecord
              source="titleConclusion"
              label="Title Conclusion"
            >
              <TextField source="titleConclusion" />
            </EmptyFieldWithRecord>
            <EmptyFieldWithRecord source="status" label="Status">
              <ChipField source="status" emptyText="-" />
            </EmptyFieldWithRecord>

            <FunctionField
              source="actions"
              label="Actions"
              className="column-actions"
              render={(record: any) => {
                return (
                  <Stack direction="row" justifyContent="flex-end">
                    {hasUpdateButtons && (
                      <Tooltip title="Edit" arrow>
                        <span>
                          <EditButton record={record} label="" />
                        </span>
                      </Tooltip>
                    )}

                    <Tooltip title="Show" arrow>
                      <span>
                        <ShowButton record={record} label="" />
                      </span>
                    </Tooltip>
                  </Stack>
                );
              }}
            />
          </Datagrid>
        )}
      </WithLoading>
    </ListBase>
  );
};

export default InterSeasonList;
