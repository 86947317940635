import React, { FC } from 'react';
import { useMediaQuery, Divider } from '@mui/material';
import { Show, SimpleShowLayout, TextField } from 'react-admin';
import ResourceDetailTopToolbar from '../../../Shared/ResourceDetailTopToolbar/ResourceDetailTopToolbar';
import { HeaderTitle } from '../../../Shared/HeaderTitle';
import { EmptyFieldWithRecord } from '../../../Shared/EmptyFieldWithRecord';

const FollowUpShow: FC = () => {
  const isSmallDevice = useMediaQuery((theme: any) =>
    theme.breakpoints.down('sm')
  );

  return (
    <Show
      actions={
        <ResourceDetailTopToolbar
          resource="Story Follow-Up"
          hasBackButton
          hasDeleteButton
        />
      }
      title={<HeaderTitle />}
      sx={{
        paddingBottom: '16px',
      }}
    >
      <SimpleShowLayout
        divider={<Divider flexItem />}
        sx={{
          padding: isSmallDevice ? '4px 8px' : '8px 16px',
        }}
      >
        <TextField source="id" />
        <EmptyFieldWithRecord source="title" label="Title">
          <TextField source="title" />
        </EmptyFieldWithRecord>
        <EmptyFieldWithRecord source="message" label="Message">
          <TextField source="message" />
        </EmptyFieldWithRecord>
      </SimpleShowLayout>
    </Show>
  );
};

export default FollowUpShow;
