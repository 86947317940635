import React from 'react';
import { Edit, SaveButton, Toolbar, ToolbarProps } from 'react-admin';
import HeaderTitle from '../../Shared/HeaderTitle/HeaderTitle';
import ResourceDetailTopToolbar from '../../Shared/ResourceDetailTopToolbar/ResourceDetailTopToolbar';
import { AirdropQuestForm } from '../components/AirdropQuestForm';

const EditActions: React.FC<ToolbarProps> = (props) => {
  return (
    <Toolbar
      {...props}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0 24px 0 24px !important',
        position: 'sticky',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 2,
      }}
    >
      <SaveButton />
    </Toolbar>
  );
};

const AirdropQuestEdit: React.FC = (props) => {
  return (
    <Edit
      actions={
        <ResourceDetailTopToolbar
          resource="AirdropQuest"
          hasBackButton
          hasDeleteButton
        />
      }
      title={<HeaderTitle />}
      transform={(data: any) => {
        const { userconfig, ...rest } = data;
        return rest;
      }}
      mutationMode="pessimistic"
      {...props}
    >
      <AirdropQuestForm toolbar={<EditActions />} />
    </Edit>
  );
};

export default AirdropQuestEdit;
