import React, { FC } from 'react';
import { Edit, SaveButton, Toolbar, ToolbarProps } from 'react-admin';
import { InterSeasonForm } from '../components/InterSeasonForm';
import HeaderTitle from '../../../Shared/HeaderTitle/HeaderTitle';
import ResourceDetailTopToolbar from '../../../Shared/ResourceDetailTopToolbar/ResourceDetailTopToolbar';

const EditActions: React.FC<ToolbarProps> = (props) => {
  return (
    <Toolbar
      {...props}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0 24px 0 24px !important',
        position: 'sticky',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 2,
      }}
    >
      <SaveButton />
    </Toolbar>
  );
};

const InterSeasonEdit: FC = (props) => {
  return (
    <Edit
      actions={
        <ResourceDetailTopToolbar
          resource="Season Story"
          hasShowButton
          hasBackButton
          hasDeleteButton
        />
      }
      title={<HeaderTitle />}
      transform={(data: any) => {
        const { buttonmessagesid, id, createdAt, ...rest } = data;
        return { ...rest };
      }}
      mutationMode="pessimistic"
      {...props}
    >
      <InterSeasonForm toolbar={<EditActions />} />
    </Edit>
  );
};

export default InterSeasonEdit;
