import React, { FC } from 'react';
import { useMediaQuery } from '@mui/material';
import { Edit, SaveButton, Toolbar, ToolbarProps } from 'react-admin';
import { PartnerForm } from '../../Shared/PartnerForm';
import ResourceDetailTopToolbar from '../../Shared/ResourceDetailTopToolbar/ResourceDetailTopToolbar';
import HeaderTitle from '../../Shared/HeaderTitle/HeaderTitle';

type FormField = any;
type FormData = any;

const EditActions: React.FC<ToolbarProps> = (props) => (
  <Toolbar
    {...props}
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0 24px 0 24px !important',
      position: 'sticky',
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 2,
    }}
  >
    <SaveButton />
  </Toolbar>
);

const PartnerEdit: FC = (props) => {
  const isSmallDevice = useMediaQuery((theme: any) =>
    theme.breakpoints.down('sm')
  );

  /**
   * Removes the fields with an empty string value from object.
   *
   * @param data Partner
   * @returns Partner
   */
  const transform = (data: FormData): FormData => {
    const sanitizedData: { [key: string]: FormField } = {};

    for (const key in data) {
      if (typeof data[key] === 'string' && data[key].length === 0) continue;

      sanitizedData[key] = data[key];
    }

    return {
      ...sanitizedData,
      createdAt: undefined,
      id: undefined,
      createdBy: undefined,
      updatedAt: undefined,
      updatedBy: undefined,
    };
  };
  return (
    <Edit
      transform={transform}
      actions={<ResourceDetailTopToolbar resource="Partner" hasShowButton />}
      title={<HeaderTitle />}
      {...(isSmallDevice && {
        sx: {
          marginBottom: '2rem',
          paddingLeft: '1rem',
          '& .MuiPaper-root': {
            border: 'none',
            boxShadow: 'none',
            height: 'auto',
          },
          '& .MuiCardContent-root': {
            padding: 0,
          },
        },
      })}
      mutationMode="pessimistic"
      {...props}
    >
      <PartnerForm toolbar={<EditActions />} />
    </Edit>
  );
};

export default PartnerEdit;
