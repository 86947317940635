import { AuthProvider, UserIdentity } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { User } from '../Shared/User/User.types';
import { AdminAccessRightListEnum } from '../Shared/Admin.types';
import {
  fetchAdminMe,
  saveMe,
  getUserPermissions,
  getMe,
  getToken,
  saveToken,
  removeToken,
  removeMe,
} from './Admin.utilities';

const useAuthProvider = (): AuthProvider => {
  const navigate = useNavigate();

  const removeUser = (): void => {
    removeToken();
    removeMe();
  };

  return {
    login: async ({
      user: { accessToken, name, photo },
    }: {
      user: User;
    }): Promise<void> => {
      if (!accessToken) {
        return Promise.reject(new Error('Access token is missing'));
      }

      saveToken(accessToken);

      try {
        const adminMe = await fetchAdminMe();
        saveMe({
          ...adminMe,
          name,
          photo,
        });
      } catch (error) {
        removeUser();
        return Promise.reject(new Error('Failed to fetch admin data'));
      }

      return Promise.resolve();
    },
    logout: (): Promise<void> => {
      // TODO: Hit the backend for logout
      removeUser();

      return Promise.resolve();
    },
    checkAuth: (): Promise<void> => {
      const token = getToken();
      if (!token) {
        return Promise.reject(new Error('No token found'));
      }

      try {
        const me = getMe();
        if (!me) {
          removeUser();
          return Promise.reject(new Error('User data is missing'));
        }
      } catch (error) {
        removeUser();
        return Promise.reject(new Error('Failed to get user data'));
      }

      return Promise.resolve();
    },
    checkError: (error): Promise<void> => {
      const status = error.status;

      if (status === 401) {
        removeUser();

        return Promise.reject();
      }

      if (status === 403) {
        navigate('/forbidden');
      }

      // other error code (403, 404, 500, etc): no need to log out

      return Promise.resolve();
    },
    getIdentity: (): Promise<UserIdentity> => {
      try {
        const { id, name, photo } = getMe();

        return Promise.resolve({
          id: id,
          fullName: name,
          avatar: photo,
        });
      } catch (error) {
        return Promise.reject(error);
      }
    },
    getPermissions: (): Promise<AdminAccessRightListEnum[]> =>
      Promise.resolve(getUserPermissions()),
  };
};

export default useAuthProvider;
