import React from 'react';
import {
  Edit,
  SaveButton,
  Toolbar,
  ToolbarProps,
  usePermissions,
} from 'react-admin';
import { QuestForm } from '../components/QuestForm';
import HeaderTitle from '../../Shared/HeaderTitle/HeaderTitle';
import ResourceDetailTopToolbar from '../../Shared/ResourceDetailTopToolbar/ResourceDetailTopToolbar';
import { useMediaQuery } from '@mui/material';
import { checkPermission } from '../../Admin/Admin.utilities';
import { AdminAccessRightListEnum } from '../../Shared/Admin.types';

const EditActions: React.FC<ToolbarProps> = (props) => {
  return (
    <Toolbar
      {...props}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0 24px 0 24px !important',
        position: 'sticky',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 2,
      }}
    >
      <SaveButton />
    </Toolbar>
  );
};

const QuestEdit: React.FC = (props) => {
  const isSmallDevice = useMediaQuery((theme: any) =>
    theme.breakpoints.down('sm')
  );
  const { permissions } = usePermissions();

  const hasUpdateButtons = checkPermission(permissions, [
    AdminAccessRightListEnum.UpdateQuests,
  ]);

  return (
    <Edit
      actions={
        <ResourceDetailTopToolbar
          resource="Quest"
          hasShowButton
          hasBackButton
          hasDeleteButton={hasUpdateButtons}
        />
      }
      title={<HeaderTitle />}
      {...(isSmallDevice && {
        sx: {
          marginBottom: '2rem',
          paddingLeft: '1rem',
          '& .MuiPaper-root': {
            border: 'none',
            boxShadow: 'none',
            height: 'auto',
          },
          '& .MuiCardContent-root': {
            padding: 0,
          },
        },
      })}
      mutationMode="pessimistic"
      {...props}
    >
      <QuestForm toolbar={<EditActions />} />
    </Edit>
  );
};

export default QuestEdit;
